import React, { useState, useEffect, RefObject, useImperativeHandle, ForwardRefRenderFunction } from 'react';

import './nav.scss';

interface NavRef {
  clickHome: () => void
  clickCta: () => void
}

export interface NavPropsRefs {
  hero: RefObject<HTMLElement>,
  clients: RefObject<HTMLElement>,
  about: RefObject<HTMLElement>,
  tech: RefObject<HTMLElement>,
  services: RefObject<HTMLElement>,
  faq: RefObject<HTMLElement>,
  contact: RefObject<HTMLElement>
}

interface NavProps {
  headerElRef: RefObject<HTMLElement>,
  refs: NavPropsRefs
}

const Nav: ForwardRefRenderFunction<NavRef, NavProps> = (props: NavProps, ref: React.ForwardedRef<NavRef>) => {

  useImperativeHandle(ref, () => {
    return {
      clickHome: () => {
        onHomeClick();
      },
      clickCta: () => {
        onNavClick(props.refs.contact);
      }
    };
  });

  const [ mobileMenu, setMobileMenu ] = useState<boolean>(false);
  const [ currentNav, setCurrentNav ] = useState<string>('hero');

  useEffect(() => {
    for (const key in props.refs) {
      if (key === window.location.hash.replace('#', '')) {
        const ref = props.refs[key as keyof typeof props.refs];
        if ((ref) && (ref.current)) {
          onNavClick(ref);
          break;
        }
      }
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const onScroll = () => {
      updateActiveLink();
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    }
  }, []); // eslint-disable-line

  const updateActiveLink = async () => {
    const position = window.scrollY + 200;

    for (const key in props.refs) {
      const ref = props.refs[key as keyof typeof props.refs];

      if (ref.current) {
        if ((position >= ref.current.offsetTop) && (position <= (ref.current.offsetTop + ref.current.offsetHeight))) {
          if (key !== currentNav) {
            setCurrentNav(key);
          }
          break;
        }
      }
    }
  }

  const buildNavClasses = (nav: string[]): string => {
    return [
      'nav-link',
      ...(nav.includes(currentNav) ? ['active'] : [])
    ].join(' ');
  }

  const onMobileToggleClick = async (event: React.MouseEvent<HTMLElement>) => {
    setMobileMenu(!mobileMenu);
  };

  const onHomeClick = () => {
    setMobileMenu(false);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const onNavClick = (ref: RefObject<HTMLElement>) => {
    if (ref && ref.current && props.headerElRef.current) {
      setMobileMenu(false);

      const offset = props.headerElRef.current.offsetHeight;
      const elementPos = ref.current.offsetTop;

      window.scrollTo({
        top: elementPos - offset,
        behavior: 'smooth'
      });

      updateActiveLink();
    }
  }

  return (
    <nav className={['component-navbar', ...(mobileMenu ? ['component-navbar-mobile'] : [''])].join(' ')}>
      <ul>
        <li>
          <a className={buildNavClasses(['hero', 'clients'])} href="#home" onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            onHomeClick();
          }}>Home</a>
        </li>
        <li>
          <a className={buildNavClasses(['about', 'whyus'])} href="#about" onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            onNavClick(props.refs.about);
          }}>About</a>
        </li>
        <li>
          <a className={buildNavClasses(['services'])} href="#services" onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            onNavClick(props.refs.services);
          }}>Services</a>
        </li>
        <li>
          <a className="cta" href="#contact" onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            onNavClick(props.refs.contact);
          }}>Contact Us</a>
        </li>
      </ul>
      <i className={['bi', 'mobile-toggle', ...(mobileMenu ? ['bi-x'] : ['bi-list'])].join(' ')} onClick={onMobileToggleClick}></i>
    </nav>
  );
};

export default React.forwardRef(Nav);