import React, { forwardRef } from 'react';

import Accordion from 'react-bootstrap/Accordion';

import './faq.scss';

interface FaqProps {

}

const Faq = forwardRef<HTMLElement, FaqProps>((props: FaqProps, ref: React.ForwardedRef<HTMLElement>) => {

  return (
    <section ref={ref} className="component-faq section-bg">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>We're often asked</h2>
        </div>

        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header><span><i className="bi bi-question-square"></i></span> Do you only provide services to London based businesses ? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></Accordion.Header>
            <Accordion.Body>
              While we are based in London, our work extends all around the world. Our team can work to your needs regardless of location.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header><span><i className="bi bi-question-square"></i></span> I'm looking for a fully bespoke website. Is this something you can help with ? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></Accordion.Header>
            <Accordion.Body>
              Yes - we create websites to your design needs. This may be with a custom content management system (CMS) or integrating with an existing CMS.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header><span><i className="bi bi-question-square"></i></span> Can you provide a solution to help with back office tasks ? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></Accordion.Header>
            <Accordion.Body>
              Absolutely - not only dealing with websites, we can provide a solution to any part of your business.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header><span><i className="bi bi-question-square"></i></span> I have concerns over my existing project, can you take a look ? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></Accordion.Header>
            <Accordion.Body>
              Yes - we don't have to get hands on in the project code, we can provide advice and consulation in any area of your project.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header><span><i className="bi bi-question-square"></i></span> We are new to building a product, can you help shape it ? <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></Accordion.Header>
            <Accordion.Body>
              Yes - our belief is that software engineering is not only about how the code is written but also about how work gets done, from development to product management and how they connect.
            </Accordion.Body>
          </Accordion.Item>
          </Accordion>

      </div>
    </section>
  );
});

export default Faq;