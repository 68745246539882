import React, { useEffect, useState } from 'react';

import './backtotop.scss';

interface BackToTopProps {
}

const BackToTop: React.FC<BackToTopProps> = (props: BackToTopProps) => {

  const [ active, setActive ] = useState<boolean>(window.scrollY > 100);

  useEffect(() => {
    const onScroll = () => {
      setActive(window.scrollY > 100);
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    }
  }, []);

  const onClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  const className = [
    'component-back-to-top',
    'd-flex',
    'align-items-center',
    'justify-content-center',
    ...(active ? ['active'] : [])
  ].join(' ');

  return (
    <a href="#home" onClick={onClick} className={className}>
      <i className="bi bi-arrow-up-short"></i>
    </a>
  );
};

export default BackToTop;