import React, { forwardRef } from 'react';

import './about.scss';

interface AboutProps {

}

const About = forwardRef<HTMLElement, AboutProps>((props: AboutProps, ref: React.ForwardedRef<HTMLElement>) => {
  return (
    <section ref={ref} className="component-about">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>About Us</h2>
        </div>

        <div className="row content">
          <div className="col-lg-6">
            <p>
              A London based engineering-led technical consultancy with an approach founded on pragmatism and honesty.
            </p>
            <ul>
              <li><i className="bi bi-box"></i><span>We value</span> honesty to ensure you can make the right choices for your business.</li>
              <li><i className="bi bi-box"></i><span>We value</span> agility enabling your business to adapt to changing conditions.</li>
              <li><i className="bi bi-box"></i><span>We value</span> delivery and feedback over descision paralysis.</li>
            </ul>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <p>With over 15 years of experience working with startups to multi-national corporations.</p>
            <p>Our aim is to shape a team from your business needs, this may be a single engineer to a fully functioning team including any product management.</p>
          </div>
        </div>

      </div>
    </section>
  );
});

export default About;