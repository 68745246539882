import React, { useRef, useState, useEffect } from 'react';
import { PathRouteProps } from 'react-router';

import Header from '../../components/header/header';
import Hero from '../../components/hero/hero';
import Clients from '../../components/clients/clients';
import About from '../../components/about/about';
import Tech from '../../components/tech/tech';
import Services from '../../components/services/services';
import Faq from '../../components/faq/faq';
import Contact from '../../components/contact/contact';
import Preloader from '../../components/preloader/preloader';
import BackToTop from '../../components/backtotop/backtotop';
import Footer from '../../components/footer/footer';

import './home.scss';

type HeaderRef = React.ElementRef<typeof Header>;

interface HomeRouteProps extends PathRouteProps {
}

const HomeRoute: React.FC<HomeRouteProps> = (props: HomeRouteProps) => {
  
  const [ loading, setLoading ] = useState<boolean>(true);

  const headerRef = React.useRef<HeaderRef>(null);

  const heroRef = useRef(null);
  const clientsRef = useRef(null);
  const aboutRef = useRef(null);
  const techRef = useRef(null);
  const servicesRef = useRef(null);
  const faqRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    setLoading(false);
  }, []); 

  return (
    <React.Fragment>
      <Header 
        ref={headerRef}
        navRefs={{
          hero: heroRef,
          clients: clientsRef,
          about: aboutRef,
          tech: techRef,
          services: servicesRef,
          faq: faqRef,
          contact: contactRef
        }}
      />
      <Hero ref={heroRef} headerRef={headerRef} />
      <main>
        <Clients ref={clientsRef} />
        <About ref={aboutRef} />
        <Tech ref={techRef} />
        <Services ref={servicesRef} />
        <Faq ref={faqRef} />
        <Contact ref={contactRef} />
      </main>
      <BackToTop />
      {loading && <Preloader />}
      <Footer />
    </React.Fragment>
  );
};

export default HomeRoute;