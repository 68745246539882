import React, { RefObject, forwardRef } from 'react';

import { HeaderRef } from '../header/header';

import heroImage from './img/hero.png';
import './hero.scss';

interface HeroProps {
  headerRef: RefObject<HeaderRef>
}

const Hero = forwardRef<HTMLElement, HeroProps>((props: HeroProps, ref: React.ForwardedRef<HTMLElement>) => {

  return (
    <section ref={ref} className="component-hero d-flex align-items-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
            <h1>Building Solutions For Your Future</h1>
            <h2>We are team of experienced engineers delivering solutions providing business value through software</h2>
            <div className="d-flex justify-content-center justify-content-lg-start">
              <a href="#contact" className="cta" onClick={() => {
                props.headerRef.current?.clickCta();
              }}>Contact Us</a>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
            <img src={heroImage} className="img-fluid animated" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
});

export default Hero;