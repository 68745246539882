import React, { forwardRef } from 'react';

import './services.scss';

interface ServicesProps {

}

const Services = forwardRef<HTMLElement, ServicesProps>((props: ServicesProps, ref: React.ForwardedRef<HTMLElement>) => {
  return (
    <section ref={ref} className="component-services">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Services</h2>
          <p></p>
        </div>

        <div className="row">
          <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-code-square"></i></div>
              <h4>Software Development</h4>
              <p>Building and enhancing products with clean, reliable and tested code either for the web or as an app.</p>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-clouds"></i></div>
              <h4>Cloud Transformation</h4>
              <p>Replatforming infrastructure improving customer experience and opening up new opportunities.</p>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-columns-gap"></i></div>
              <h4>Agile Coaching</h4>
              <p>Enable better ways of working and setting accurate expectations within timeframes and budget.</p>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
            <div className="icon-box">
              <div className="icon"><i className="bi bi-graph-up-arrow"></i></div>
              <h4>Development Process</h4>
              <p>Creating efficient continuous deployment and development strategies.</p>
            </div>
          </div>

        </div>

      </div>
    </section>
  );
});

export default Services;