import React from 'react';
import { PathRouteProps } from 'react-router';
import { Link, useLocation } from 'react-router-dom'
import './not-found.scss';

interface NotFoundRouteProps extends PathRouteProps {

}

const NotFoundRoute: React.FC<NotFoundRouteProps> = (props: NotFoundRouteProps) => {

  const location = useLocation();

  return (
    <main>
      <div className="page-not-found">
        <h1>I'm sorry, we couldn't find that page for you.</h1>
        <p>The requested page <span className="pathname">{location.pathname}</span> was not found.</p>
        <p><Link to="/"><i className="bi bi-house"></i> Home</Link></p>
      </div>
    </main>
  );
};

export default NotFoundRoute;