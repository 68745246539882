import React, { useState, useEffect, ForwardRefRenderFunction, useImperativeHandle } from 'react';
import Nav, { NavPropsRefs } from '../nav/nav';

import './header.scss';

export interface HeaderRef extends NavRef {
}

interface HeaderProps {
  navRefs: NavPropsRefs
}

type NavRef = React.ElementRef<typeof Nav>;

const Header: ForwardRefRenderFunction<HeaderRef, HeaderProps> = (props: HeaderProps, ref: React.ForwardedRef<HeaderRef>) => {

  const navRef = React.useRef<NavRef>(null);
  const headerRef = React.createRef<HTMLElement>();

  const [ headerScrolled, setHeaderScrolled ] = useState<boolean>(window.scrollY > 100);

  useImperativeHandle(ref, () => {
    return {
      clickHome: () => {
        navRef.current?.clickHome();
      },
      clickCta: () => {
        navRef.current?.clickCta();
      }
    };
  });

  useEffect(() => {
    const onScroll = () => {
      setHeaderScrolled(window.scrollY > 100);
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    }
  }, []);

  return (
    <header ref={headerRef} className={['fixed-top', ...(headerScrolled ? ['header-scrolled'] : [])].join(' ')}>
      <div className="container d-flex align-items-center">
        <h1 className="logo me-auto"><a href="#home" onClick={() => {
            navRef.current?.clickHome();
          }}>Bedican</a></h1>
        <Nav 
          ref={navRef}
          headerElRef={headerRef}
          refs={props.navRefs}
        />
      </div>
    </header>
  );
};

export default React.forwardRef(Header);