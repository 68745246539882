import React, { forwardRef } from 'react';

import clientBbc from './img/bbc.jpg';
import clientVirgin from './img/virginmedia.png';
import clientEconomist from './img/economist.png';
import clientKurtgeiger from './img/kurtgeiger.png';
// import clientTempur from './img/tempursealy.jpg';
import clientCruk from './img/cruk.png';
import clientBritishcycling from './img/britishcycling.webp';

import './clients.scss';

interface ClientsProps {

}

const Clients = forwardRef<HTMLElement, ClientsProps>((props: ClientsProps, ref: React.ForwardedRef<HTMLElement>) => {

  return (
    <section ref={ref} className="component-clients section-bg">
      <div className="container">
        <div className="row" data-aos="zoom-in">
          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={clientBbc} className="img-fluid" alt="" />
          </div>
          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={clientVirgin} className="img-fluid" alt="" />
          </div>
          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={clientEconomist} className="img-fluid" alt="" />
          </div>
          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={clientKurtgeiger} className="img-fluid" alt="" />
          </div>
          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={clientBritishcycling} className="img-fluid" alt="" />
          </div>
          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={clientCruk} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
});

export default Clients;