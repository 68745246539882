import React from 'react';

import './footer.scss';

interface FooterProps {
}

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  return (
    <footer>
      <div className="container footer-bottom clearfix">
        <div className="copyright">
          &copy; Copyright 2024 <strong><span>Bedican&reg; Ltd</span></strong>.
        </div>
      </div>
    </footer>
  );
};

export default Footer;