import React from 'react';

import './preloader.scss';

interface PreloaderProps {
}

const Preloader: React.FC<PreloaderProps> = (props: PreloaderProps) => {
  return (
    <div className="component-preloader"></div>
  );
};

export default Preloader;