import React, { forwardRef } from 'react';

import Accordion from 'react-bootstrap/Accordion';

import whyus from './img/tech.png';

import './tech.scss';

interface TechProps {

}

const Tech = forwardRef<HTMLElement, TechProps>((props: TechProps, ref: React.ForwardedRef<HTMLElement>) => {

  return (
    <section ref={ref} className="component-tech section-bg">
      <div className="container-fluid" data-aos="fade-up">

        <div className="row">

          <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

            <div className="content">
              <h3>Technology enjoyed by our engineers <strong>we love to make things!</strong></h3>
              <p>
                It's important to drill down in to the technology used.
              </p>
            </div>

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header><span><i className="bi bi-code-square"></i></span> Development <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></Accordion.Header>
                <Accordion.Body>
                  <p>
                    Our engineers use a selection of technologies and believe that a good solution is not bound by any particular choice.
                  </p>
                  <ul>
                    <li><i className="bi bi-arrow-down-right-square"></i>React</li>
                    <li><i className="bi bi-arrow-down-right-square"></i>Angular</li>
                    <li><i className="bi bi-arrow-down-right-square"></i>Node.js</li>
                    <li><i className="bi bi-arrow-down-right-square"></i>TypeScript</li>
                    <li><i className="bi bi-arrow-down-right-square"></i>PHP</li>
                    <li><i className="bi bi-arrow-down-right-square"></i>.Net</li>
                    <li><i className="bi bi-arrow-down-right-square"></i>Python</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header><span><i className="bi bi-clouds"></i></span> Cloud <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></Accordion.Header>
                <Accordion.Body>
                  <p>
                    Working with Amazon Web Services (AWS), Google Cloud Platform (GCP) and Azure to ensure the best cloud provider is used to suit the product needs.
                  </p>
                  <ul>
                    <li><i className="bi bi-arrow-down-right-square"></i>Compute instances and virtual machines.</li>
                    <li><i className="bi bi-arrow-down-right-square"></i>Containerization with self or provider managed services.</li>
                    <li><i className="bi bi-arrow-down-right-square"></i>Serverless and event-driven compute engines.</li>
                    <li><i className="bi bi-arrow-down-right-square"></i>Fully managed relational or document databases.</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header><span><i className="bi bi-bezier2"></i></span> Operations <i className="bi bi-chevron-down icon-show"></i><i className="bi bi-chevron-up icon-close"></i></Accordion.Header>
                <Accordion.Body>
                  <p>
                    Streamlining the operational process helps us work smarter in the development cycle, which in turn creates benefit across the whole product delivery.
                  </p>
                  <ul>
                    <li><i className="bi bi-arrow-down-right-square"></i>Infrastructure as Code (IaC) with Terraform or AWS CDK.</li>
                    <li><i className="bi bi-arrow-down-right-square"></i>Continuous integration and delivery using build and deploy pipelines.</li>
                    <li><i className="bi bi-arrow-down-right-square"></i>Logging, monitoring and alerting to react to failure or changing demand.</li>
                    <li><i className="bi bi-arrow-down-right-square"></i>Product lifecycle management in order to keep a lean process.</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

          </div>

          <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" style={{backgroundImage: `url(${whyus})`}} data-aos="zoom-in" data-aos-delay="150">&nbsp;</div>
        </div>

      </div>
    </section>
  );
});

export default Tech;